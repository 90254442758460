import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { db } from "../functions/Firebase";
import { getDoc, doc } from "@firebase/firestore";
import { FormatDate, ParseLinks } from "../functions/Formatters";
import Icon from "../components/Icon";
import { MapImage } from "../components/Cards";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import Lottie from 'react-lottie'
import placeLoading from '../assets/animations/place.json'

export default function PlaceScreen() {
  const { id } = useParams();
  const [data, setData] = useState<{
    nombre: string;
    description: string;
    img: string[];
    city?: string;
    // fecha?: number;
    location: number[];
    desc?: string;
  }>({ nombre: "Cargando...", description: "", img: [], location: [] });
  const [isLoading, setLoading] = useState(true);
  async function get() {
    const ref = doc(db, "places", id || "");
    const item = await getDoc(ref);
    //@ts-ignore
    if (item.exists()) setData(item.data());
    else
      setData({
        nombre: "No encontramos el lugar que buscas",
        img: [],
        description: "",
        location: [],
      });
    setLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  const description = useMemo(() => ParseLinks(data.description), [data]);

  return (
    <div className="screen-container">
      <div className="event-container">
        {!isLoading ? (
          <>
            <Helmet>
              <title>In Situ | {data.nombre}</title>
              <meta name="description" content={data.desc} />
            </Helmet>
            <div className="left">
              {data.img.length ?  <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop
                centeredSlides
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={{ el: ".swiper-pagination", clickable: true }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
              >
                {data.img.map((img) => (
                  <img src={img} alt={data.nombre} />
                ))}
              </Swiper> : <Lottie
            options={{
              animationData: placeLoading,
              autoplay: true,
              loop: true
            }}
            height={300}
            width={300}
          />}

              {/* <div>
                {data.fecha && (
                  <>
                    <div className="info-date">{time.date}</div>
                    <p className="info-time">
                      <Icon name="calendar-outline" /> {time.hour}
                    </p>
                  </>
                )}
              </div> */}
            </div>
            <div className="right">
              <h1 className="color-title">{data.nombre}</h1>
              <h3 style={{textTransform: 'capitalize'}}>{data.city}</h3>
              {!data.description && (
                <Link to="/places">
                  <h2>Ver mas lugares increibles</h2>
                </Link>
              )}
              <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
              {!!data.location.length && (
                <MapImage location={data.location} markerColor="#1890ff" />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="left">
              <div className="img loader">
                <div className="loader-item"></div>
              </div>
              <div>
                <div className="info-date loader">
                  <div className="loader-item"></div>
                </div>
                <div className="loader">
                  <div className="loader-item"></div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="color-title loader">
                <div className="loader-item"></div>
              </div>
              <div className="desc loader">
                <div className="loader-item"></div>
              </div>
              <div className="desc loader">
                <div className="loader-item"></div>
              </div>
              <div className="desc loader">
                <div className="loader-item"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
