import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FormatDate } from "../functions/Formatters";
import defaultImage from "../assets/default.png";
import Lottie from 'react-lottie';
import mapLoading from "../assets/animations/loading_map.json";

export function LoaderCard() {
  return (
    <div className="card loader">
      <div className="loader-item" />
      {/* <div className="loader-item" /> */}
    </div>
  );
}

interface EventCardProps {
  id: string;
  img?: string;
  date?: number;
  name: string;
  location: any;
  city?: string
}

export function EventCard({ id, img, date, name, location,city }: EventCardProps) {
  return (
    <Link
      to={(date ? '/events/' : '/places/')+ id}
      className="card"
      state={{ backgroundLocation: location }}
      onClick={() =>
        sessionStorage.setItem("lastItem", JSON.stringify({ img, date, name }))
      }
    >
      <motion.div layout className="content" onClick={() => console.log(name)}>
        <motion.img src={img || defaultImage} layoutId={id} />
        <div>
          <p style={{textTransform: city ? 'capitalize': 'none'}}>{date ? FormatDate(date).date : city}</p>
          <h2>{name}</h2>
        </div>
      </motion.div>
    </Link>
  );
}

interface MapImageProps {
  location: number[];
  markerColor: string;
}

export function MapImage({ location, markerColor }: MapImageProps) {
  const [isLoading, setLoading] = useState(true);
  return (
    <div className='map-image'>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            options={{
              animationData: mapLoading,
              autoplay: true,
              loop: true
            }}
            height={200}
            width={200}
          />
        </div>
      )}
      <img
        onLoad={() => setLoading(false)}
        style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: 'contain' }}
        src={
          "https://maps.googleapis.com/maps/api/staticmap?center=" +
          `${location[0]},${location[1]}` +
          "&markers=color:" +
          `0x1890ff|${location[0]},${location[1]}` +
          "&style=feature:road|element:geometry.fill|color:0xd1e5e6" +
          "&style=feature:road|element:geometry|saturation:-30|lightness:10" +
          "&style=feature:road|element:geometry.stroke|saturation:25|lightness:25" +
          "&style=feature:poi|element:geometry|saturation:20" +
          "&style=feature:poi.park|element:geometry.fill|color:0x3ebec1" +
          "&style=feature:poi.park|element:geometry|saturation:-20|lightness:20" +
          "&style=element:geometry|color:0x63b5e5&style=feature:water|lightness:-25" +
          "&style=feature:landscape|element:geometry|saturation:30|lightness:30" +
          "&zoom=15&size=400x400&key=AIzaSyAJbKaUEdaRCjDmxHhXog-oRwov-4Zqm-E"
        }
      />
    </div>
  );
}
