import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { db } from "../../functions/Firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  orderBy,
  where,
} from "@firebase/firestore";
import Icon from "../../components/Icon";
import { EventCard, LoaderCard } from "../../components/Cards";
import { AnimatePresence, motion } from "framer-motion";
import { homeScreenProps } from "../../functions/interfaces";
const cities = ["sabaneta", "envigado", "itagui", "medellin"];

export default function PlacesScreen({visible}:homeScreenProps) {
  const location = useLocation();
  const [discoverPlaces_, setDiscoverPlaces] = useState<any[]>([]);
  const [mainPlaces_, setMainPlaces] = useState<any[]>([]);
  const [allPlaces_, setAllPlaces] = useState<any[]>([]);
  const [viewType, setViewType] = useState<"complete" | "initial">("initial");
  async function get() {
    const ref = collection(db, "places");
    const mainQuery = query(
      ref,
      where(
        "city",
        "==",
        cities[Math.round(Math.random() * (cities.length - 1))]
      ),
      limit(7)
    );
    const discoverQuery = query(
      ref,
      orderBy(
        Math.round(Math.random()) ? "nombre" : "id",
        Math.round(Math.random()) ? "desc" : "asc"
      ),
      limit(7)
    );
    const discoverDocs = await getDocs(discoverQuery);
    let discoverPlaces: any[] = [];
    discoverDocs.forEach((event) => {
      discoverPlaces.push({ ...event.data(), id_: event.id });
    });
    setDiscoverPlaces(discoverPlaces);
    let mainPlaces: any[] = [];
    const mainDocs = await getDocs(mainQuery);
    mainDocs.forEach((event) => {
      mainPlaces.push({ ...event.data(), id_: event.id });
    });
    setMainPlaces(mainPlaces);
  }

  async function getAll() {
    const query_ = query(collection(db, "places"), orderBy("nombre"));
    const places = await getDocs(query_);
    let eventList: any[] = [];
    places.forEach((event) => {
      eventList.push({ ...event.data(), id_: event.id });
    });
    setAllPlaces(eventList);
  }
  useEffect(() => {
    get();
  }, []);

  return (
    <div className={"screen-container "+visible}>
      <div className="search-bar">
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Buscar..."
        />
        <button>
          <Icon name="filter" color="white" />
        </button>
      </div>
      <AnimatePresence>
        {viewType == "initial" && (
          <>
            <div className="title-container">
              <h1 className="color-title">Recomendados</h1>
              <h3
                className="hoverable"
                onClick={() => {
                  if (!allPlaces_.length) getAll();
                  setViewType("complete");
                }}
              >
                Ver todos <Icon name="open" />
              </h3>
            </div>
            <motion.section
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="grid-container"
            >
              {discoverPlaces_.map((item) => (
                <EventCard
                  key={item.id_}
                  name={item.nombre}
                  img={item.img[0]}
                  city={item.city}
                  id={item.id_}
                  location={location}
                />
              ))}
              {!discoverPlaces_.length && (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              )}
            </motion.section>
            <h1 className="color-title">Descubre</h1>
            <section className="grid-container">
              {mainPlaces_.map((item) => (
                <EventCard
                  key={item.id_}
                  name={item.nombre}
                  img={item.img[0]}
                  city={item.city}
                  id={item.id_}
                  location={location}
                />
              ))}
              {!mainPlaces_.length && (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              )}
            </section>
          </>
        )}
        {viewType == "complete" && (
          <>
            <div className="title-container">
              <h3 className="hoverable" onClick={() => setViewType("initial")}>
                <Icon name="chevron-left" /> Volver
              </h3>
            </div>
            <motion.section
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="grid-container"
            >
              {allPlaces_.map((item) => (
                <EventCard
                  key={item.id_}
                  name={item.nombre}
                  img={item.img}
                  city={item.city}
                  id={item.id_}
                  location={location}
                />
              ))}
              {!allPlaces_.length && (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              )}
            </motion.section>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
