interface LoaderProps {
  className?: string;
  loading: boolean;
}
export function CircleLoader({ className, loading }: LoaderProps) {
  return loading ? (
    <div className={className}>
      <div className=" triple-spinner ">
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
      </div>
    </div>
  ) : null;
}
