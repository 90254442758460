import { useState } from "react";
import Icon from "../components/Icon";
import EventsScreen from "./home/Events";
import MapScreen from "./home/Map";
import PlacesScreen from "./home/Places";

export default function HomeScreen() {
  const [currentIndex, setIndex] = useState(0);
  return (
    <div className="home">
      <nav className="App-nav">
        <ul>
          <a
            href="#"
            onClick={() => {
              setIndex(0);
            }}
            className={"nav-item " + (currentIndex == 0)}
          >
            <Icon name="calendar-outline" />
          </a>
          <a
            href="#"
            onClick={() => {
              setIndex(1);
            }}
            className={"nav-item " + (currentIndex == 1)}
          >
            <Icon name="map-outline" />
          </a>
          <a
            href="#"
            onClick={() => {
              setIndex(2);
            }}
            className={"nav-item " + (currentIndex == 2)}
          >
            <Icon name="compass-outline" />
          </a>
        </ul>
      </nav>
  
        <EventsScreen key='events' visible={currentIndex == 0} />
        <MapScreen key='map' visible={currentIndex == 1} />
        <PlacesScreen key='places' visible={currentIndex == 2} />
    </div>
  );
}
