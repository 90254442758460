const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
export function FormatDate(date: number) {
  const date_ = new Date(date);
  const month = months[date_.getMonth()];
  const day = date_.getDate();
  let hour = date_.getHours();
  let time = "am";
  if (hour > 12) {
    time = "pm";
    hour -= 12;
  }
  const finalHour = ("0" + hour.toString()).substr(-2);
  const finalMinutes = ("0" + date_.getMinutes().toString()).substr(-2);
  return {
    date: month + "\n" + day,
    hour: `${finalHour}:${finalMinutes} ${time}`,
  };
}

export function ParseLinks(text: string) {
  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    (match, space, url)=> {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return space + '<a href="' + hyperlink + '" target="_blank" >' + url + "</a>";
    }
  );
}
