import React, { Component, useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker as NativeMarker,
} from "react-google-maps";
import theme from "../assets/lightMapTheme.json";
import blueMarker from "../assets/event.png";
import purpleMarker from "../assets/place.png";

export default class Map extends Component {
  //@ts-ignore
  constructor(props) {
    super(props);
    //@ts-ignore
    this.map = React.createRef();
  }

  state = {
    location: {
      lat: 6.25184,
      lng: -75.56359,
    },
    markerPos: {
      lat: 6.15074,
      lng: -75.46249,
    },
    zoom: 13,
  };

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return (
      //@ts-ignore
      this.props.children[0].length !== nextProps.children[0].length || this.props.children[1].length !== nextProps.children[1].length
    );
  }

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          //@ts-ignore
          ref={this.map}
          //@ts-ignore
          google={this.props.google}
          defaultZoom={11.5}
          defaultOptions={{ styles: theme }}
          defaultCenter={{
            lat: this.state.location.lat,
            lng: this.state.location.lng,
          }}
        >
          <div className="map-legend">
            <img src={blueMarker} alt="eventos simbolo" />
            <span>Eventos</span>
            <img src={purpleMarker} alt="lugares simbolo" />
            <span>Lugares</span>
          </div>
          {this.props.children}
        </GoogleMap>
      ))
    );
    return (
      <AsyncMap
        //@ts-ignore
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBUuJ1hcG-FGvYSHq3ClbSBKl9zt1Q_N68&libraries=places"
        //@ts-ignore
        loadingElement={<div className="map" />} //@ts-ignore
        containerElement={<div className="map-container" />}
        //@ts-ignore
        mapElement={<div className="map-element" />}
      />
    );
  }
}

interface MarkerProps {
  name: string;
  type: "event" | "place";
  location: number[];
  onClick: Function;
}

export function Marker({ name, type, location, onClick }: MarkerProps) {
  const [infoVisble, setInfoVisible] = useState(false);
  return (
    <NativeMarker
      title={name}
      defaultIcon={type == "event" ? blueMarker : purpleMarker}
      position={{
        lat: location[0],
        lng: location[1],
      }}
      onClick={() => setInfoVisible(true)}
    >
      {infoVisble && (
        <InfoWindow
          onCloseClick={() => setInfoVisible(false)}
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
        >
          <div onClick={()=>onClick()}>
            <h3>{name}</h3>
          </div>
        </InfoWindow>
      )}
    </NativeMarker>
  );
}
