import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyB4HCey3NXcOdCFfrNcoT5Y4kirxj9T90Q",
  authDomain: "in-situapp.firebaseapp.com",
  databaseURL: "https://in-situapp.firebaseio.com",
  projectId: "in-situapp",
  storageBucket: "in-situapp.appspot.com",
  messagingSenderId: "102532931565",
  appId: "1:102532931565:web:6c0f01481381ad79"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app)