import { useParams, useNavigate, useLocation } from "react-router";
import { motion } from "framer-motion";
import Icon from "./Icon";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../functions/Firebase";
import { FormatDate, ParseLinks } from "../functions/Formatters";
import Swiper from "react-id-swiper";
import twemoji from "twemoji";

interface dataType {
  nombre: string;
  description: string;
  img: string | string[];
  organizador?: string;
  fecha?: number;
  name?: string;
  date?: number;
  city?: string;
  finalHour?: number;
}
interface ModalProps {
  type?: "event" | "place";
  id?: string;
  onClose?: Function;
}
export default function Modal(props: ModalProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState<dataType>({
    nombre: "Cargando...",
    description: "",
    img: "",
  });
  // const [isLoading, setLoading] = useState(true);

  async function get() {
    const ref = doc(db, isEvent ? "eventos" : "places", id || props.id || "");
    const item = await getDoc(ref);
    //@ts-ignore
    if (item.exists()) setData(item.data());
  }
  useEffect(() => {
    const tempData = sessionStorage.getItem("lastItem");
    if (tempData) setData(JSON.parse(tempData));
    get();
  }, []);

  useEffect(()=>{
    setTimeout(()=>{
      let descElement = document.getElementById('description');
    console.info('twemoji');
    console.info(descElement);
    if(descElement != null){
      twemoji.parse(descElement);
    }
    }, 50);
    
  }, [data])

  const isEvent = useMemo(
    () => location.pathname.includes("event") || props.type == "event",
    []
  );
  const description = useMemo(() => ParseLinks(data.description), [data]);
  const date = useMemo(() => {
    return {
      ...FormatDate(data.date || data.fecha || 0),
      final: data.finalHour ? FormatDate(data.finalHour).hour : "",
    };
  }, [data]);

  return (
    <>
      <motion.div
        onClick={(e) => {
          if (props.onClose) props.onClose();
          else navigate(-1);
        }}
        className="modal-backdrop"
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      />
      <motion.div
        layout
        className="modal-panel visible"
        exit={{ width: 0 }}
        animate={{ width: 500 }}
        initial={{ width: 0 }}
      >
        <header className="modal-header">
          {(data.fecha || data.date) && (
            <div className="info-date">{date.date}</div>
          )}
          <div>
            <h2>{data.name || data.nombre}</h2>
            {data.organizador || data.city ? (
              <span
                style={{ textTransform: data.city ? "capitalize" : "none" }}
              >
                {data.organizador || data.city}
              </span>
            ) : (
              <div className="loader" style={{ height: "1rem" }}>
                <div className="loader-item"></div>
              </div>
            )}
          </div>

          <Icon
            name="close"
            onClick={() => {
              if (props.onClose) props.onClose();
              else navigate(-1);
            }}
          />
        </header>
        <section className="modal-content">
          {data.fecha && (
            <div className="time">
              <Icon name="clock" />
              <p>
                {date.hour}
                {data.finalHour ? ` - ${date.final}` : ""}
              </p>
            </div>
          )}
          {!!data.img && typeof data.img == "string" ? (
            <motion.img layoutId={id} src={data.img} alt="poster" />
          ) : !!data.img && typeof data.img != "string" ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop
              centeredSlides
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
            >
              {data.img.map((img) => (
                <motion.img layoutId={id} src={img} alt="poster" />
              ))}
            </Swiper>
          ) : null}
          {data.description ? (
            <p
            id="description"
              className="desc"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          ) : (
            <div>
              <div className="loader" style={{ height: "1rem", marginTop: 10 }}>
                <div className="loader-item"></div>
              </div>
              <div className="loader" style={{ height: "1rem", marginTop: 10 }}>
                <div className="loader-item"></div>
              </div>
              <div className="loader" style={{ height: "1rem", marginTop: 10 }}>
                <div className="loader-item"></div>
              </div>
            </div>
          )}
        </section>
      </motion.div>
    </>
  );
}

interface ModalWindowProps {
  onClose: Function;
  title: string;
  icon: string;
  visible: boolean;
  children: ReactElement;
}

export function ModalWindow({
  onClose,
  title,
  visible,
  icon,
  children,
}: ModalWindowProps) {
  return visible ? (
    <>
      <motion.div
        className="modal-backdrop"
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      ></motion.div>
      <motion.div
        className="modal"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        onClick={(e) => {
          onClose();
        }}
      >
        <motion.div
          layout
          className="modal-window"
          onClick={(e) => e.stopPropagation()}
        >
          <header className="modal-header">
            <div>
              <h2>
                {title}
                <Icon name={icon} />
              </h2>
            </div>
            <Icon
              name="close"
              onClick={() => {
                onClose();
              }}
            />
          </header>
          <div className="modal-content">{children}</div>
        </motion.div>
      </motion.div>
    </>
  ) : null;
}
