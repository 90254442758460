import { useState, useEffect } from "react";
import Map, { Marker } from "../../components/Map";
import { db } from "../../functions/Firebase";
import { collection, getDocs, where, query } from "@firebase/firestore";
import { CircleLoader } from "../../components/Loader";
import Modal from "../../components/Modal";
import { homeScreenProps } from "../../functions/interfaces";

export default function MapScreen({visible}:homeScreenProps) {
  const [events, setEvents] = useState<any[]>([]);
  const [places, setPlaces] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<
    { id: string; type: "event" | "place" } | undefined
  >();

  async function get() {
    const eventsRef = collection(db, "eventos");
    const eventsQuery = query(eventsRef, where('type', '==', 0))
    const placesRef = collection(db, "places");
    const eventsDocs = await getDocs(eventsQuery);
    let events_: any[] = [];
    let places_: any[] = [];
    eventsDocs.forEach((snap) => {
      events_.push({ ...snap.data(), id_: snap.id });
    });
    setEvents(events_);
    const placesDocs = await getDocs(placesRef);
    placesDocs.forEach((place) =>
      places_.push({ ...place.data(), id_: place.id })
    );
    setPlaces(places_);
    setLoading(false);
  }
  useEffect(() => {
    get();
  }, []);
  return (
    <div className={"screen-container map " +visible}>
      <CircleLoader loading={loading} className="absolute" />
      <Map>
        {events.map((event, index) => (
          <Marker
            type="event"
            key={event.nombre + index.toString()}
            name={event.nombre}
            location={event.location}
            onClick={() => {
              sessionStorage.setItem("lastItem", JSON.stringify(event));
              setSelected({ id: event.id_, type: "event" });
            }}
          />
        ))}
        {places.map((place, index) => (
          <Marker
            key={place.nombre + index.toString()}
            name={place.nombre}
            location={place.location}
            type="place"
            onClick={() => {
              sessionStorage.setItem("lastItem", JSON.stringify(place));
              setSelected({ id: place.id_, type: "event" });
            }}
          />
        ))}
      </Map>
      {!!selected && (
        <Modal
          type={selected.type || "event"}
          id={selected.id || ""}
          onClose={() => setSelected(undefined)}
        />
      )}
    </div>
  );
}
