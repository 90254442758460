import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { db } from "../../functions/Firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  orderBy,
} from "@firebase/firestore";
import Icon from "../../components/Icon";
import { EventCard, LoaderCard } from "../../components/Cards";
import { AnimatePresence, motion } from "framer-motion";
import Searchbar from "../../components/Searchbar";
import { homeScreenProps } from "../../functions/interfaces";

export default function EventsScreen({visible}:homeScreenProps) {
  const location = useLocation();
  const [discoverEvents_, setDiscoverEvents] = useState<any[]>([]);
  const [mainEvents_, setMainEvents] = useState<any[]>([]);
  const [allEvents_, setAllEvents] = useState<{ all: any[]; filtered: any[] }>({
    all: [],
    filtered: [],
  });
  const [viewType, setViewType] = useState<"complete" | "initial">("initial");
  async function get() {
    const ref = collection(db, "eventos");
    const discoverQuery = query(ref, limit(7));
    const mainQuery = query(ref, orderBy("likes", "desc"), limit(7));
    const discoverDocs = await getDocs(discoverQuery);
    let discoverEvents: any[] = [];
    discoverDocs.forEach((event) => {
      discoverEvents.push({ ...event.data(), id_: event.id });
    });
    setDiscoverEvents(discoverEvents);
    let mainEvents: any[] = [];
    const mainDocs = await getDocs(mainQuery);
    mainDocs.forEach((event) => {
      mainEvents.push({ ...event.data(), id_: event.id });
    });
    setMainEvents(mainEvents);
  }

  async function getAll(set?: boolean) {
    const query_ = query(collection(db, "eventos"), orderBy("date"));
    const events = await getDocs(query_);
    let eventList: any[] = [];
    events.forEach((event) => {
      eventList.push({ ...event.data(), id_: event.id });
    });
    if (set) {
      setAllEvents({ all: eventList, filtered: eventList });
      return [];
    } else return eventList;
  }

  async function search(val: string) {
    if (viewType == "initial") setViewType("complete");
    let all = allEvents_.all;
    if (!allEvents_.all.length) all = await getAll();
    const filtered = all.filter(
      (e) =>
        e.nombre.toLowerCase().includes(val) ||
        e.city.toLowerCase().includes(val) ||
        e.organizador.toLowerCase().includes(val)
    );
    setAllEvents({ all, filtered });
  }
  useEffect(() => {
    get();
  }, []);

  return (
    <div className={"screen-container "+visible}>
      <Searchbar
        onSearch={search}
        onReset={() =>
          setAllEvents({ ...allEvents_, filtered: allEvents_.all })
        }
      />
      <AnimatePresence>
        {viewType == "initial" && (
          <>
            <div className="title-container">
              <h1 className="color-title">Descubre</h1>
              <h3
                className="hoverable"
                onClick={() => {
                  if (!allEvents_.all.length) getAll(true);
                  setViewType("complete");
                }}
              >
                Ver todos <Icon name="open" />
              </h3>
            </div>
            <motion.section
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="grid-container"
            >
              {discoverEvents_.map((item) => (
                <EventCard
                  key={item.id_}
                  name={item.nombre}
                  img={item.img}
                  date={item.fecha ?? item.date}
                  id={item.id_}
                  location={location}
                />
              ))}
              {!discoverEvents_.length && (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              )}
            </motion.section>
            <h1 className="color-title">Destacados</h1>
            <section className="grid-container">
              {mainEvents_.map((item) => (
                <EventCard
                  key={item.id_}
                  name={item.nombre}
                  img={item.img}
                  date={item.fecha ?? item.date}
                  id={item.id_}
                  location={location}
                />
              ))}
              {!mainEvents_.length && (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              )}
            </section>
          </>
        )}
        {viewType == "complete" && (
          <>
            <div className="title-container">
              <h3 className="hoverable" onClick={() => setViewType("initial")}>
                <Icon name="chevron-left" /> Volver
              </h3>
            </div>
            <motion.section
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="grid-container"
            >
              {allEvents_.filtered.map((item) => (
                <EventCard
                  key={item.id_}
                  name={item.nombre}
                  img={item.img}
                  date={item.fecha ?? item.date}
                  id={item.id_}
                  location={location}
                />
              ))}
              {!allEvents_.all.length && (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              )}
            </motion.section>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
