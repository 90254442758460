import React, { useState, useEffect } from "react";
import { Name } from "./components/logo";
import Icon from "./components/Icon";
import {
  Route,
  Routes,
  NavLink,
  useLocation,
  Outlet,
  Navigate,
  Link,
} from "react-router-dom";
import "./App.css";
import "./styles/animations.css";
import "./styles/main.css";
import "./styles/icons/fluent.css";
import EventScreen from "./screens/Event";
import NotFoundScreen from "./screens/NotFound";
import Modal, { ModalWindow } from "./components/Modal";
import { AnimatePresence, motion } from "framer-motion";

import bancolombia from "./assets/payment/bancolombia_nequi.png";
import qr_banc from "./assets/payment/bancolombia_qr.png";
import daviplata from "./assets/payment/daviplata_civicapay.png";
import qr_dav from "./assets/payment/daviplata_qr.jpg";
import mercadopago from "./assets/payment/mercadopago.png";
import paypal from "./assets/payment/paypal.png";
import qr_paypal from "./assets/payment/paypal_qr.png";
import PlaceScreen from "./screens/Place";
import LegalScreen from "./screens/Legal";
import HomeScreen from "./screens/Home";

function Layout() {
  const [modal, setModal] = useState(0);
  const [darkTheme, setDarkTheme] = useState(false);
  useEffect(() => {
    const theme = localStorage.getItem("dark");
    if (theme == "true") {
      document.getElementsByTagName("body")[0].classList.add("dark");
      setDarkTheme(true);
    }
  }, []);
  const location = useLocation();
  const modals = [
    {
      title: "",
      icon: "",
      body: <div></div>,
    },
    {
      title: "Donar",
      icon: "donate",
      body: (
        <ul className="payment-list">
          <li className="list-item">
            <img src={bancolombia} alt="bancolombia" />
            <button type="button" onClick={() => setModal(2)}>
              <Icon name="qr" />
            </button>
          </li>
          <li className="list-item">
            <img src={daviplata} alt="DaviPlata" />
            <button type="button" onClick={() => setModal(3)}>
              <Icon name="qr" />
            </button>
          </li>
          {/* <li className="list-item">
            <img src={bancolombia} alt="Nequi" />
            <button type="button" onClick={() => setModal(4)}>
              <Icon name="qr" />
            </button>
          </li> */}
          <li className="list-item">
            <img src={mercadopago} alt="MercadoPago" />
            <a href="https://link.mercadopago.com.co/malakmalak" target="_blank" type="button">
              <span>Abrir</span> <Icon name="open" />
            </a>
          </li>
          <li className="list-item">
            <img src={paypal} alt="PayPal" />
            <div>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=3PWUV6CBNCUQQ"
                target="_blank"
                type="button"
              >
               <span>Abrir</span> <Icon name="open" />
              </a>
              <button type="button" onClick={() => setModal(4)}>
                <Icon name="qr" />
              </button>
            </div>
          </li>
        </ul>
      ),
    },
    {
      title: "Donar en Bancolombia | Nequi",
      icon: "donate",
      body: <img className="qr" src={qr_banc} />,
    },
    {
      title: "Donar en DaviPlata | CívicaPay",
      icon: "donate",
      body: <img className="qr" src={qr_dav} />,
    },
    {
      title: "Donar en PayPal",
      icon: "donate",
      body: <img className="qr" src={qr_paypal} />,
    },
    {
      title: "Ajustes",
      icon: "settings-outline",
      body: (
        <ul className="payment-list">
          <li className="list-item settings">
            <label htmlFor="dark-check">Tema oscuro</label>
            <input
              type="checkbox"
              name="dark-check"
              id="dark-check"
              checked={darkTheme}
              onChange={(e) => {
                const body_ = document.getElementsByTagName("body")[0];
                const checked = e.target.checked;
                setDarkTheme(checked);
                if (checked) body_.classList.add("dark");
                else body_.classList.remove("dark");
                localStorage.setItem("dark", checked.toString());
              }}
            />
          </li>
          <li className="list-item settings" onClick={() => setModal(1)}>
            <span>Donar</span>
            <Icon name="donate" />
          </li>
          <a
            href="https://creadoresinsitu.web.app"
            className="list-item settings"
            target="_blank" rel="noopener noreferrer"
          >
            <span>Publicar evento</span> <Icon name="person-voice" />
          </a>

            <a href="https://play.google.com/store/apps/details?id=com.insitu.jc2" className="list-item settings" target="_blank" rel="noopener noreferrer">
              <span>Descargar para android</span>
              <Icon name="open" />
            </a>
        </ul>
      ),
    },
  ];
  return (
    <div>
      {/* <svg
        className="back-svg"
        height="55%"
        width={600}
        style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
      >
        <ellipse cx="150" cy="0" rx="50%" ry="100%" fill="var(--accent)" />
      </svg> */}
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
       <div 
       className="title">
       {location.pathname != '/' && <Link to='/' ><Icon name='chevron-left' /></Link>}
       <Link to='/' ><h1>In Situ</h1></Link> 
        </div>
        
        <div className="settings-button" onClick={()=>setModal(5)}>
          <Icon name="settings-outline" />
        </div>
      </header>
      {/* <nav className="App-nav">
        <ul>
          <NavLink
            to="events"
            className={({ isActive }) => "nav-item " + isActive}
          >
            <Icon name="calendar-outline" />
          </NavLink>
          <NavLink
            to="map"
            className={({ isActive }) => "nav-item " + isActive}
          >
            <Icon name="map-outline" />
          </NavLink>
          <NavLink
            to="places"
            className={({ isActive }) => "nav-item " + isActive}
          >
            <Icon name="compass-outline" />
          </NavLink>
        </ul>
      </nav> */}
      <AnimatePresence>
        <ModalWindow
          title={modals[modal].title}
          icon={modals[modal].icon}
          onClose={() => setModal(0)}
          visible={!!modal}
        >
          {modals[modal].body}
        </ModalWindow>
      </AnimatePresence>
      <Outlet />
      <footer>
        <div className="buttons-container">
          <a
            href="https://creadoresinsitu.web.app"
            target="_blank"
            className="button"
          >
            <Icon name="person-voice" />
            <span>Publicar evento</span>
          </a>
          <div className="button" onClick={() => setModal(6)}>
            <Icon name="settings-outline" />
            <span>Ajustes</span>
          </div>
          <div className="button" onClick={() => setModal(1)}>
            <Icon name="donate" />
            <span>Donar</span>
          </div>
        </div>
        <div className="label">
          <p>In Situ, Malak, todos los derechos reservados</p>
          <p>
            Todas las marcas y logos son propiedad de sus respectivos dueños
          </p>
        </div>
      </footer>
    </div>
  );
}

function App() {
  const location = useLocation();
  let state = location.state as { backgroundLocation?: Location };
  return (
    <motion.div className="App">
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<Layout />}>
        <Route index element={<HomeScreen/>} />
          {/* <Route path="events" element={<EventsScreen />} /> */}
          <Route path="events/:id" element={<EventScreen />} />
          <Route path="privacy" element={<LegalScreen />} />
          <Route path="terms" element={<LegalScreen />} />
          {/* <Route path="map" element={<MapScreen />} /> */}
          {/* <Route path="places" element={<PlacesScreen />} /> */}
          <Route path="places/:id" element={<PlaceScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Routes>
      <AnimatePresence>
        {state?.backgroundLocation && (
          <Routes>
            <Route path="/events/:id" element={<Modal />} />
            <Route path="/places/:id" element={<Modal />} />
          </Routes>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default App;
