import { useState } from "react";
import Icon from "./Icon";

interface SearchProps {
  onSearch: (val: string) => void;
  onReset: Function;
}

export default function Searchbar({ onSearch, onReset }: SearchProps) {
  const [value, setValue] = useState("");
  return (
    <form
      className="search-bar"
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(value.trim().toLowerCase());
      }}
    >
      <input
        type="search"
        name="search"
        id="search"
        placeholder="Buscar..."
        value={value}
        onChange={(e) => {
            const val = e.target.value
          setValue(val);
          if (!val) onReset();
        }}
      />
      <button onClick={() => onSearch(value)}>
        <Icon name="filter" color="white" />
      </button>
    </form>
  );
}
