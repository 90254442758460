import { useLocation } from "react-router"

export default function LegalScreen(){
    const {pathname, key} = useLocation()
    return (<div className="screen-container legal">
        <h1 style={{ padding: 10, marginBottom: 10, borderBottomColor: '#000', borderBottomWidth: 1, backgroundColor:'var(--primary)', borderRadius: 15 }}>{pathname == '/terms' ?
                    'Términos y condiciones' : 'Política de privacidad'}</h1>
                {pathname == '/terms' ? <>
                    <h2>Información relevante</h2>
                    <p >
                        Es requisito necesario para el uso de algunas características de In Situ(Aplicación Móvil), que lea y acepte los siguientes 
                        Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios implicará que usted ha 
                        leído y aceptado los Términos y Condiciones de Uso (incluyendo nuestra Política de Privacidad) en el 
                        presente documento. Será necesario el ingreso de datos personales fidedignos y definición de una contraseña.
                        </p><p >
                        Malak; (La Compañía) se reserva el derecho de enmendar, complementar o suspender total o parcialmente la 
                        Aplicación Móvil en forma ocasional. Asimismo, la Compañía se reserva el derecho de cambiar los Términos y 
                        Condiciones en cualquier momento, con vigencia inmediata a partir del momento que se actualiza la Aplicación 
                        Móvil. El término “Usuario” se refiere a todo individuo o entidad que use, acceda, descargue, instale, obtenga 
                        o brinde información desde y hacia esta Aplicación Móvil.
                        </p><p >
                        El usuario puede elegir y cambiar la clave para su acceso en cualquier momento, 
                        esta se encuentra encriptada y solo puede ser modificada por el Usuario.

                        El Usuario debe suspender el uso de la Aplicación Móvil inmediatamente si no están de acuerdo o no aceptan 
                        todos estos Términos y Condiciones. La compañía se reserva el derecho de eliminar o prohibir a cualquier Usuario 
                        la utilización de esta Aplicación Móvil a su sola discreción.</p>
                    <h2>Actualizaciones de la aplicación movil</h2>
                    <p >
                        La compañía puede solicitar a los Usuarios que actualicen su versión de la Aplicación Móvil en cualquier 
                        momento. Aunque se harán todos los esfuerzos por conservar las configuraciones y preferencias personales del 
                        Usuario, seguirá existiendo la posibilidad de que las mismas se pierdan.</p>
                    <h2>Propiedad</h2>
                    <p >
                        Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. 
                        Todos los eventos, noticias y canales de información son propiedad de los proveedores del contenido, gestores culturales y/o 
                        creadores vinculados.</p>
                    <h2>Terceros</h2>
                    <p >
                    Los prestadores de servicio de telefonía inalámbrica de los Usuarios, los fabricantes y vendedores de los 
                    dispositivos móviles en los que el Usuario descargue, instale, utilice o acceda a la Aplicación Móvil, el 
                    creador del sistema operativo para los dispositivos móviles de los Usuarios y el operador de cualquier tienda de 
                    aplicaciones o servicios similares mediante los cuales los usuarios obtengan la Aplicación Móvil, si existieran, 
                    (en conjunto, los “Terceros”) no son parte de estos Términos y Condiciones y no son propietarios ni responsables 
                    de la Aplicación Móvil. Los Terceros no brindan ninguna garantía en relación con la Aplicación Móvil. No son 
                    responsables del mantenimiento u otros servicios de soporte técnico de la Aplicación Móvil y no serán responsables 
                    ante ningún otro reclamo, pérdidas, imputación de responsabilidades, daños y perjuicios, costos o gastos vinculados
                     con la Aplicación Móvil.

                    El Usuario reconoce y acepta que los Terceros y sus empresas subsidiarias son terceros beneficiarios de estos 
                    Términos y Condiciones y que ellos tienen el derecho (y se asumirá que han aceptado tal derecho) de ejercer estos
                     Términos y Condiciones ante los usuarios como terceros beneficiarios.

                    La Aplicación Móvil fue creada para la versión más reciente disponible en el mercado de los sistemas operativos de 
                    los dispositivos móviles de los Usuarios y pueden surgir inconvenientes de compatibilidad cuando se utilicen 
                    versiones anteriores. La cobertura de la red inalámbrica y la velocidad de la red de Wi-Fi varían según el 
                    proveedor y la ubicación geográfica. La compañia no se responsabiliza por las limitaciones y/o fallas en el 
                    funcionamiento de ningún servicio inalámbrico o Wi-Fi que se use para acceder a esta Aplicación Móvil ni por 
                    la seguridad de los servicios inalámbricos o Wi-Fi. Asimismo, no se responsabiliza de los cargos o tarifas por 
                    uso de redes de datos, que son exclusiva responsabilidad del Usuario.
                    </p>
                    <h2>Responsabilidad limitada</h2>
                    <p >
                        LOS TERCEROS, IN SITU, MALAK; Y SUS EMPRESAS MATRICES Y AFILIADAS, JUNTO CON LOS RESPECTIVOS DIRECTIVOS, DIRECTORES, 
                        PERSONAL, EMPLEADOS Y REPRESENTANTES (EN CONJUNTO REFERIDOS COMO LAS “PARTES EXENTAS”) NO SERÁN RESPONSABLES 
                        NI ESTARÁN SUJETOS A ACCIONES LEGALES, Y POR LA PRESENTE EL USUARIO RENUNCIA A TODO RECLAMO, DEMANDA, 
                        IMPUTACIÓN DE RESPONSABILIDADES, CAUSA LEGAL, QUERELLA, RECLAMACIÓN DE DAÑOS Y PERJUICIOS, POR RAZÓN DE, 
                        ENTRE OTROS, DAÑOS DIRECTOS, INDIRECTOS, ACCIDENTALES, INCIDENTALES, DERIVADOS, CIRCUNSTANCIALES, 
                        EXTRAORDINARIOS, ESPECIALES O PUNITIVOS DE CUALQUIER NATURALEZA CON RESPECTO A ESTA APLICACIÓN MÓVIL 
                        (INCLUYENDO LOS PRODUCTOS, SERVICIOS Y CONTENIDOS DE LAS PARTES EXENTAS), AÚN CUANDO LAS PARTES EXENTAS 
                        HUBIERAN SIDO ADVERTIDAS DE LA POSIBILIDAD DE DICHOS DAÑOS. EL ÚNICO RECURSO DE LOS USUARIOS ANTE TALES 
                        RECLAMOS, DEMANDAS, IMPUTACIÓN DE RESPONSABILIDADES, CAUSAS LEGALES, QUERELLAS O RECLAMOS DE DAÑOS Y PERJUICIOS 
                        ES PONER FIN AL USO DE ESTA APLICACIÓN MÓVIL.</p>

                </> :
                    <>
                        <p >El presente Política de Privacidad establece los términos en que In Situ usa y
                        protege la información que es proporcionada por sus usuarios al momento de utilizar su aplicación. Esta compañía
                        está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de
                        información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de
                        acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo
                        o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse
                    que está de acuerdo con dichos cambios.</p>
                        <h2>Información que es recogida</h2>
                        <p >Nuestra aplicación podrá recoger su correo electrónico y su nombre. Así mismo cuando sea
                    necesario podrá ser requerida información específica como su ubicación pero esta no será almacenada de ninguna manera.</p>
                        <h2>Uso de la información recogida</h2>
                        <p >
                            Nuestra aplicación emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, que cada uno pueda establecer sus favoritos y compartir opiniones con los demás usuarios.
                        In Situ está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>
                        <h2>Enlaces a Terceros</h2>
                        <p >Esta aplicación pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>
                        <h2>Control de su información personal</h2>
                        <p >En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestra aplicación y solicitar la eliminación de su cuenta de usuario.

                        Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.

                    In Situ se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>
                    </>
                }
    </div>)
}