import { Link } from "react-router-dom";

export default function NotFoundScreen(){
    return(<div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
        <img src="./android-icon-192x192.png" />
        <h1>404</h1>
        <h2>Página no encontrada</h2>
        <br />
        <Link to='/'>Ir al inicio</Link>
        </div>)
}