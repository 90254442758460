import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { db } from "../functions/Firebase";
import { getDoc, doc } from "@firebase/firestore";
import { FormatDate, ParseLinks } from "../functions/Formatters";
import Icon from "../components/Icon";
import { MapImage } from "../components/Cards";
import {Helmet} from 'react-helmet';
import Lottie from 'react-lottie'
import eventLoading from '../assets/animations/calendar.json'
import twemoji from "twemoji";

export default function EventScreen() {
  const { id } = useParams();
  const [data, setData] = useState<{
    nombre: string;
    description: string;
    img: string;
    organizador?: string;
    fecha?: number;
    location: number[];
    desc?: string
    type: 0|1,
    lugar?:string
  }>({ nombre: "Cargando...", description: "", img: "", location: [], type: 0 });
  const [isLoading, setLoading] = useState(true);
  async function get() {
    const ref = doc(db, "eventos", id || "");
    const item = await getDoc(ref);
    //@ts-ignore
    if (item.exists()) setData(item.data());
    else
      setData({
        nombre: "Evento no encontrado",
        img: "",
        description: "",
        location: [],
        type: 1
      });
    setLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  useEffect(()=>{
    setTimeout(()=>{
      let descElement = document.getElementById('description');
    console.info('twemoji');
    console.info(descElement);
    if(descElement != null){
      twemoji.parse(descElement);
    }
    }, 250);
    
  }, [data])

  const time = useMemo(() => FormatDate(data.fecha || 0), [data]);
  const parsed = useMemo(() => {return {description: ParseLinks(data.description), place: ParseLinks(data.lugar || "")}}, [data]);

  return (
    <div className="screen-container">
      <div className="event-container">
        {!isLoading ? (
          <>
          <Helmet>
            <title>In Situ | {data.nombre}</title>
            <meta name="description" content={data.desc} />
          </Helmet>
            <div className="left"> 
              <div>
                {data.fecha && (
                  <>
                    <div className="info-date">{time.date}</div>
                    <p className="info-time">
                      <Icon name="calendar-outline" /> {time.hour}
                    </p>
                  </>
                )}
              </div>{!!data.desc ? !!data.img && <img src={data.img} alt={data.nombre} /> : <Lottie
            options={{
              animationData: eventLoading,
              autoplay: true,
              loop: true
            }}
            height={300}
            width={300}
          />}
            </div>
            <div className="right">
              <h1 className="color-title">{data.nombre}</h1>
              {!data.description && <Link to='/events'><h2>Ver mas eventos</h2></Link>}
              {data.organizador && <h3>{data.organizador}</h3>}
              <p id="description"
                className="desc"
                dangerouslySetInnerHTML={{ __html: parsed.description }}
              ></p>
              <p dangerouslySetInnerHTML={{__html: parsed.place}}></p>
              {!!data.location.length && data.type == 0 && (
                <MapImage location={data.location} markerColor="#1890ff" />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="left">
              <div className="img loader">
                <div className="loader-item"></div>
              </div>
              <div>
                <div className="info-date loader">
                  <div className="loader-item"></div>
                </div>
                <div className="loader">
                  <div className="loader-item"></div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="color-title loader">
                <div className="loader-item"></div>
              </div>
              <div className="desc loader">
                <div className="loader-item"></div>
              </div>
              <div className="desc loader">
                <div className="loader-item"></div>
              </div>
              <div className="desc loader">
                <div className="loader-item"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
