interface IconProps {
  name: string;
  size?: number | string;
  color?: string;
  onClick?: Function;
}

export default function Icon({ name, size, color, onClick }: IconProps) {
  return (
    <i
      className={`icon-${name} ${onClick ? 'hoverable' : ''}`}
      style={{ ...(size ? {fontSize: size} : ''), ...(color ? {color: color} : '') }}
      onClick={() => {
        if (onClick) onClick();
      }}
    />
  );
}
